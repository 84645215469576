import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function TenantDetails() {
  const { tenantId } = useParams();
  const [tenant, setTenant] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [emailOrUsername, setEmailOrUsername] = useState(""); // Input field for adding members or owners
  const [memberError, setMemberError] = useState("");
  const [ownerError, setOwnerError] = useState("");
  const [applicationName, setApplicationName] = useState(""); // Input for new application
  const [appSecret, setAppSecret] = useState(""); // To show the one-time secret
  const [applications, setApplications] = useState([]);
  const [createApplicationError, setCreateApplicationError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTenantDetails = async () => {
      try {
        const response = await axios.get(`/tenant/${tenantId}`);
        setTenant(response.data.tenant);
      } catch (error) {
        setError("Could not fetch tenant details");
      } finally {
        setLoading(false);
      }
    };

    const fetchTenantApplications = async () => {
      try {
        const appResponse = await axios.get(`/tenant/${tenantId}/applications`);
        setApplications(appResponse.data.applications);
      } catch (error) {
        setError("Could not fetch tenant applications");
      } finally {
        setLoading(false);
      }
    };

    fetchTenantDetails();
    fetchTenantApplications();
  }, [tenantId]);

  const handleAddMember = async () => {
    try {
      await axios.post(`/tenant/${tenantId}/add-member`, {
        emailOrUsername,
      });
      setMemberError(""); // Clear error on success
      // Refresh tenant details after adding member
      const response = await axios.get(`/tenant/${tenantId}`);
      setTenant(response.data.tenant);
    } catch (error) {
      setMemberError("Error adding member");
    }
  };

  const handleAddOwner = async () => {
    try {
      await axios.post(`/tenant/${tenantId}/add-owner`, {
        emailOrUsername,
      });
      setOwnerError(""); // Clear error on success
      // Refresh tenant details after adding owner
      const response = await axios.get(`/tenant/${tenantId}`);
      setTenant(response.data.tenant);
    } catch (error) {
      setOwnerError("Error adding owner");
    }
  };

  const handleCreateApplication = async () => {
    try {
      const response = await axios.post(
        `/application/${tenantId}/application`,
        {
          applicationName,
        }
      );
      setAppSecret(response.data.applicationSecret); // Show one-time secret
      setApplications([...applications, response.data.application]); // Update the application list
    } catch (error) {
      setCreateApplicationError("Error creating application");
    }
  };

  const handleViewApplication = (applicationId) => {
    navigate(`/applications/${applicationId}`);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  if (!tenant) return <p>Tenant not found</p>;

  return (
    <div>
      <h2>Tenant Details</h2>
      <p>Tenant ID: {tenant.tenantId}</p>
      <p>Owners: {tenant.owners.map((owner) => owner.username).join(", ")}</p>
      <p>
        Members: {tenant.members.map((member) => member.username).join(", ")}
      </p>

      <h3>Add Member or Owner</h3>
      <input
        type="text"
        value={emailOrUsername}
        onChange={(e) => setEmailOrUsername(e.target.value)}
        placeholder="Enter email or username"
      />

      <div style={{ marginTop: "10px" }}>
        <button onClick={handleAddMember}>Add Member</button>
        {memberError && <p style={{ color: "red" }}>{memberError}</p>}
      </div>

      <div style={{ marginTop: "10px" }}>
        <button onClick={handleAddOwner}>Add Owner</button>
        {ownerError && <p style={{ color: "red" }}>{ownerError}</p>}
      </div>

      <h3>Applications</h3>
      {applications.length > 0 ? (
        <ul>
          {applications.map((app) => (
            <li key={app._id}>
              {app.applicationName}{" "}
              <button onClick={() => handleViewApplication(app._id)}>
                View Details
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p>No applications found</p>
      )}

      <h3>Create New Application</h3>
      <input
        type="text"
        value={applicationName}
        onChange={(e) => setApplicationName(e.target.value)}
        placeholder="Enter application name"
      />
      <button onClick={handleCreateApplication}>Create Application</button>
      {appSecret && (
        <p>
          <strong>Application Secret: </strong>
          {appSecret} (Save this secret, you will not see it again)
        </p>
      )}
      {createApplicationError && (
        <p style={{ color: "red" }}>{createApplicationError}</p>
      )}
    </div>
  );
}

export default TenantDetails;
