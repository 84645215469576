import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Tenants() {
    const [tenants, setTenants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTenants = async () => {
            try {
                const response = await axios.get("/tenant/my-tenants/all");
                setTenants(response.data.tenants);
            } catch (error) {
                console.error("Error fetching tenants", error);
                setError("Error fetching tenants" + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTenants();
    }, []);

    const handleViewTenant = (tenantId) => {
        navigate(`/tenants/${tenantId}`);
    };

    if (loading) {
        return <p>Loading tenants...</p>;
    }

    if (error) {
        return <p style={{ color: "red" }}>{error}</p>;
    }

    return (
        <div>
            <h2>Your Tenants</h2>
            {tenants.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Tenant Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tenants.map((tenant) => (
                            <tr key={tenant._id}>
                                <td>{tenant.tenantId}</td>
                                <td>
                                    <button onClick={() => handleViewTenant(tenant.tenantId)}>
                                        View Details
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No tenants found.</p>
            )}
        </div>
    );
}

export default Tenants;
