import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Account({ onAuthChange }) {
  const [user, setUser] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccountInfo = async () => {
      try {
        const response = await axios.get("/auth/account/me"); // Adjust this endpoint as needed
        setUser(response.data.user);
      } catch (error) {
        setError("Could not fetch account information");
      }
    };

    fetchAccountInfo();
  }, []);

  const handleLogout = async () => {
    try {
      await axios.get("/auth/logout");
      onAuthChange(false);
      navigate("/login");
    } catch (error) {
      setError("Error logging out");
    }
  };

  const handleViewTenants = () => {
    navigate("/tenants");
  };

  if (error) return <p style={{ color: "red" }}>{error}</p>;

  return (
    <div style={{ paddingTop: "20px", paddingLeft: "20px" }}>
      <h2>Account Information</h2>
      {user ? (
        <div>
          <p>Email: {user.email}</p>
          <p>Username: {user.username}</p>
          <p>Phone Number: {user.phoneNumber}</p>
          <p>Roles: {user.roles.join(", ")}</p>
          <button onClick={handleLogout}>Logout</button>
          <button onClick={handleViewTenants} style={{ marginLeft: "10px" }}>
            View Tenants
          </button>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Account;
