import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function CreateTenant() {
    const [tenantName, setTenantName] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleCreateTenant = async () => {
        try {
            const response = await axios.post("/tenant", { tenantName });
            if (response.status === 201) {
                navigate("/tenants");
            }
        } catch (error) {
            setError("Error creating tenant: " + error.message);
        }
    };

    return (
        <div>
            <h2>Create Tenant</h2>
            <input
                type="text"
                value={tenantName}
                onChange={(e) => setTenantName(e.target.value)}
                placeholder="Tenant Name"
            />
            <button onClick={handleCreateTenant}>Create</button>
            {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
    );
}

export default CreateTenant;