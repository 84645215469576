import React, { useEffect, useState } from "react";
import axios from "axios";
import "./spinner.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Signup from "./Signup";
import Login from "./Login";
import Account from "./Account"
import Tenants from "./Tenant";
import TenantDetails from "./TenantDetails";
import CreateTenant from "./CreateTenant";
import ApplicationDetails from "./ApplicationDetails";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get("/auth/check"); // Endpoint to check if user is authenticated
        setIsAuthenticated(response.status === 200);
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  if (loading) {
    return (
      <div style={{ paddingTop: "20px", paddingLeft: "20px" }}>
        <h2>Loading...</h2>
        <div className="spinner"></div>{" "}
        {/* Add your spinner component or styling here */}
      </div>
    );
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/signup"
          element={
            isAuthenticated ? (
              <Navigate to="/account" />
            ) : (
              <Signup onAuthChange={setIsAuthenticated} />
            )
          }
        />
        <Route
          path="/login"
          element={
            isAuthenticated ? (
              <Navigate to="/account" />
            ) : (
              <Login onAuthChange={setIsAuthenticated} />
            )
          }
        />
        <Route
          path="/account"
          element={
            isAuthenticated ? (
              <Account onAuthChange={setIsAuthenticated} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/tenants"
          element={
            isAuthenticated ? (
              <Tenants />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/tenants/:tenantId"
          element={
            isAuthenticated ? (
              <TenantDetails />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/create-tenant"
          element={
            isAuthenticated ? (
              <CreateTenant />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/applications/:applicationId"
          element={
            isAuthenticated ? (
              <ApplicationDetails />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="*"
          element={<Navigate to={isAuthenticated ? "/account" : "/login"} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
